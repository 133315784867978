import { types as t } from 'mobx-state-tree';
import { useCreateStore, useProvider, useStore } from 'mobx-store-provider';
import * as React from 'react';
import { environment } from '@doltech/core/lib/environment/environment';

// const unsupportedUserAgentsPattern = /Windows.*Chrome|Windows.*Firefox|Linux.*Chrome/;

const defaultPollConfig = {
  pingUrl: 'https://tuhocielts.dolenglish.vn/api/health',
  timeout: 1000,
  interval: 15000,
};

let pollingId = null;

const PollingConfigStore = t
  .model('PollingConfigStore', {
    timeout: t.number,
    interval: t.number,
    pingUrl: t.maybeNull(t.string),
  })
  .views((self) => ({
    isPollingEnabled() {
      return Boolean(self.pingUrl);
    },
  }))
  .actions((self) => ({
    updatePingUrl(pingUrl) {
      self.pingUrl = pingUrl;
    },
  }));

export const NetworkDetectorStore = t
  .model('NetworkDetectorStore', {
    isOnline: t.boolean,
    pollingConfig: PollingConfigStore,
  })
  .views((self) => ({
    get networkState() {
      if (self.isOnline) return 'ONLINE';
      return 'OFFLINE';
    },
    get isOffline() {
      return self.isOnline === false;
    },
  }))
  .actions((self) => ({
    setOnline(isOnline) {
      self.isOnline = isOnline;
    },
  }));

const useNetworkDetectEventStream = (networkStore: any): void => {
  const makePingRequest = (): void => {
    if (!networkStore.pollingConfig.isPollingEnabled()) return;
    fetch(networkStore.pollingConfig.pingUrl)
      .then(() => {
        networkStore.setOnline(true);
      })
      .catch(() => {
        networkStore.setOnline(false);
      });
  };

  React.useEffect(() => {
    // Listen to the online status
    window.addEventListener('online', makePingRequest);

    // Listen to the offline status
    window.addEventListener('offline', makePingRequest);

    // Specify how to clean up after this effect for performance improvment
    return () => {
      window.removeEventListener('online', makePingRequest);
      window.removeEventListener('offline', makePingRequest);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (typeof window === 'undefined') return;

    const checkOnlineStatus = (): void => {
      const isOnline = navigator.onLine;
      networkStore.setOnline(isOnline);
    };

    checkOnlineStatus();
    makePingRequest();

    if (networkStore.pollingConfig.isPollingEnabled()) {
      pollingId = setInterval(makePingRequest, networkStore.pollingConfig.interval);

      return () => {
        clearInterval(pollingId);
      };
    }
    window.ononline = () => {
      networkStore.setOnline(true);
    };

    window.onoffline = () => {
      networkStore.setOnline(false);
    };

    return () => {
      window.ononline = null;
      window.onoffline = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [networkStore]);
};

export const useNetworkDetectStore = () => {
  return useStore(NetworkDetectorStore);
};

export const withNetworkDetect =
  ({ pingUrl }: any) =>
  (Component: any) =>
  (props: any) => {
    const networkDetectorCreateStore = useCreateStore(NetworkDetectorStore, {
      isOnline: true,
      isOffline: false,
      pollingConfig: PollingConfigStore.create({
        ...defaultPollConfig,
        pingUrl: environment.production
          ? 'https://tuhocielts.dolenglish.vn/api/health'
          : 'https://int-api.dolenglish.vn/s3-service-v2/api/health',
      }),
    });
    if (pingUrl) {
      networkDetectorCreateStore.pollingConfig.updatePingUrl(pingUrl);
    }
    const NetworkDetectorStoreProvider = useProvider(NetworkDetectorStore);

    useNetworkDetectEventStream(networkDetectorCreateStore);

    return (
      <NetworkDetectorStoreProvider value={networkDetectorCreateStore}>
        <Component {...props} />
      </NetworkDetectorStoreProvider>
    );
  };
