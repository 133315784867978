import { useAuthManager } from '@doltech/core/lib/auth/authManager';
import { isMatch } from 'matcher';
import { useRouter } from 'next/router';
import * as React from 'react';

interface WithLoadingProps {
  isLoading: boolean;
}

interface WithLMSStudentAuthOptions {
  publicRoutes: string[];
}

export const withLMSStudentAuth =
  (options: WithLMSStudentAuthOptions) =>
  (AuthComponent: React.ComponentType<any | string>) =>
  (props: WithLoadingProps) => {
    const authManager = useAuthManager();
    const router = useRouter();
    const isHandleAuth = React.useRef(false);
    const isPublicRoute = options?.publicRoutes.some((url) => isMatch(router.pathname, url));

    const handleAuth = React.useCallback(async () => {
      if (!isHandleAuth.current) {
        isHandleAuth.current = true;

        if (!authManager.isLoggedIn()) {
          sessionStorage.setItem('redirectTo', window.location.href);
          router.push('/');
          return;
        }
      }
    }, [authManager, router]);

    React.useEffect(() => {
      /* if current path is not public -> Checking authorized */
      if (!isPublicRoute) {
        handleAuth();
      }
    }, [handleAuth]);

    return <AuthComponent {...props} auth={authManager} />;
  };
