import * as React from 'react';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { DndProvider } from 'react-dnd';

const isBrowser = () => typeof window !== 'undefined';

const withDndHtml5Backend = (Component: any) => (props: any) => {
  const isMobile =
    isBrowser() &&
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

  return (
    <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
      <Component {...props} />
    </DndProvider>
  );
};
export { withDndHtml5Backend };
