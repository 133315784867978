import * as React from 'react';
import { isInAppBrowser } from './utils';
import styled from 'styled-components';

const Main = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: grid;
  justify-content: center;
  z-index: 9999;
  .content {
    color: white;
    max-width: calc(100vw - 24px);
    margin: 24px auto 0;
    display: grid;
    grid-auto-rows: max-content;
    gap: 4px;
    p {
      margin: 0;
    }
    button {
      margin-top: 24px;
      border-radius: 8px;
      padding: 8px 16px;
      outline: none;
      border: none;
      transition: all 0.3s ease;
      height: 40px;
      cursor: pointer;
      background: #d14242;
      color: white;
    }
  }
`;

const openInSystemBrowser = (url) => {
  window.open(url, '_system');
};

const AlertYouAreInAppBrowser = () => {
  return (
    <Main>
      <div className="content">
        <p>Bạn đang vào web dol bằng ứng dụng của bên thứ 3 không hỗ trợ đăng nhập</p>
        <p>Vui lòng mở trang web này bằng trình duyệt mặc định như chrome hay safari</p>
        <button
          onClick={() => {
            openInSystemBrowser(window.location.href);
          }}
        >
          Mở bằng trình duyệt mặc định
        </button>
      </div>
    </Main>
  );
};

const PreventInAppBrowserView = ({ children }) => {
  const [inAppBrowser, setIsInAppBrowser] = React.useState(false);

  React.useEffect(() => {
    setIsInAppBrowser(isInAppBrowser());
  }, []);

  return (
    <>
      {inAppBrowser && <AlertYouAreInAppBrowser />}
      {children}
    </>
  );
};

export const withPreventInAppBrowserView = <P extends object>(
  Component: React.ComponentType<P>
) => {
  return class WithPreventInAppBrowserView extends React.Component<P> {
    render() {
      return (
        <PreventInAppBrowserView>
          <Component {...this.props} />
        </PreventInAppBrowserView>
      );
    }
  };
};
